<div class="absolute inset-0 z-50">
  <div
    class="fixed bg-black bg-opacity-75 backdrop-blur-lg h-full w-full flex place-items-center place-content-center"
  >
    <video
      class="h-[60vh]"
      playsinline
      autoplay="autoplay"
      controls
      loop
      [src]="videoUrl"
      type="video/mp4"
    ></video>
    <div class="buttons">
      <button class="change-button" (click)="closePopup()">
        <fa-icon
          [icon]="timesIcon"
          class="text-dark-text absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </button>
    </div>
  </div>
</div>
