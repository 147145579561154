import { OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  Input
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons';

export const VIDEO_MODAL_REF = new InjectionToken<OverlayRef>(
  'VIDEO_MODAL_REF',
);


@Component({
  selector: 'app-video-modal',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent {
  @Input() videoUrl: string;

  readonly timesIcon = faTimes;

  private readonly overlayRef = inject(VIDEO_MODAL_REF);

  constructor() {
  }

  closePopup() {
    this.overlayRef.dispose();
  }
}
