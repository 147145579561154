import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ControlType } from '../../../shared/enums/controlType.enum';

export interface VideoFormConfig {
  sku: string;
  formSteps: FormState[];
  constParams?: any;
  sex?: string;

  //from database
  name?: string;
  productType?: ProductType;
  thumbnailUrl?: string;
  headerBackgroundUrl?: string;
  price?: number;
}

export interface FormState {
  number: number;
  description: string;
  layoutDirection: LayoutDirection;
  headerControls?: ControlGroup;
  controlGroups: ControlGroup[];
}

export interface ControlGroup {
  differentBackground?: boolean;
  controls: Control[];
}

export interface Control {
  id: string;
  controlType: ControlType;
  name?: string;
  label?: string;
  tooltip?: string;
  options?: SelectOption[];
  value?: any;
  placeholder?: string;
  required?: boolean;
  optionLoaderParams?: OptionLoaderParams;
  exclude?: boolean;
  noAudio?: boolean;
  hideSuccessMark?: boolean;
  sexDependant?: boolean;

  displayConditions?: Condition[];
  isHidden?: boolean;

  valueDisplayName?: string;

  //image
  imageAspectRatio?: number;

  //ai
  hideOptionsDropdown?: boolean;
  maxLenght?: number;
}

export interface SelectOption {
  value: string;
  displayName?: string;
  sex?: string;

  //tile
  icon?: IconDefinition;
  iconColor?: string;
}

export interface OptionLoaderParams {
  group: string;
  subGroup?: string;
  sex?: string;
}

export interface Condition {
  id: string;
  value: any;
}

export enum ProductType {
  Video = 1,
  Letter = 2,
  Calendar = 3,
}

export enum LayoutDirection {
  Horizontal = 1,
  Vertical = 2,
}